import {
  FacebookLoginClient,
  InitParams,
  LoginOptions,
  createScriptEle,
  isFacebookApp,
} from '@greatsumini/react-facebook-login';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { IHandleStartRegistrationProps } from 'types/ui/Registration';
import {
  getFacebookProfileFromCode,
  loginWithFacebook,
  validateFacebookAccessTokenAndEmailForRegistration,
} from 'utils/api/auth';
import { FACEBOOK_APP_ID } from 'utils/constants';
import * as Sentry from '@sentry/browser';

interface FacebookCompletionProps {
  accessToken: string;
  userID: string;
  email: string;
  first_name?: string;
  last_name?: string;
}

const initParams: InitParams = {
  appId: FACEBOOK_APP_ID,
  version: 'v9.0',
  xfbml: false,
  cookie: false,
  localStorage: true,
};

export const useFacebookSignIn = (
  handleSuccessfulLogin: (token: string, name: string) => Promise<void>,
  handleStartRegistration: (
    props: IHandleStartRegistrationProps
  ) => Promise<void>,
  setError: (error?: string) => void
) => {
  const router = useRouter();
  const isRedirected = useRef<boolean>(false);

  const [isFacebookLoading, setIsFacebookLoading] = useState(false);

  const loginDialogProps = {
    redirect_uri:
      typeof window !== 'undefined' ? location.origin + location.pathname : '/',
    state: 'facebookdirectlogin',
    response_type: 'code',
    client_id: FACEBOOK_APP_ID,
  };

  const registerDialogProps = {
    redirect_uri:
      typeof window !== 'undefined' ? location.origin + location.pathname : '/',
    state: 'facebookdirectregister',
    response_type: 'code',
    client_id: FACEBOOK_APP_ID,
  };

  const loadFB = async () => {
    const language = 'en_US';
    await createScriptEle(
      'facebook-jssdk',
      `https://connect.facebook.net/${language}/all.js`
    );

    await FacebookLoginClient.loadSdk(language);

    FacebookLoginClient.init(initParams);
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    setTimeout(loadFB, 500);
  }, [router.isReady]);

  const completeSignIn = async (response: {
    accessToken: string;
    userID: string;
    email: string;
    first_name: string;
    last_name: string;
  }) => {
    if (!response.accessToken || !response.userID) {
      Sentry.captureMessage(
        `Facebook login failed no response.accessToken ${response.accessToken} or response.userID ${response.userID}`
      );
      return;
    }

    const loginResponse = await loginWithFacebook(
      response.accessToken,
      response.userID
    );

    if (loginResponse.successful) {
      await handleSuccessfulLogin(
        loginResponse.token,
        loginResponse.welcomeName
      );
    } else {
      Sentry.captureMessage(
        `loginResponse not successful ${loginResponse.reason}`
      );

      setError(loginResponse.reason);
    }
  };

  const completeRegistration = async (response: {
    accessToken: string;
    userID: string;
    email: string;
    first_name: string;
    last_name: string;
  }) => {
    if (!response.accessToken || !response.userID) {
      Sentry.captureMessage(
        `Facebook login failed no response.accessToken ${response.accessToken} or response.userID ${response.userID}`
      );
      return;
    }

    const registrationResult =
      await validateFacebookAccessTokenAndEmailForRegistration(
        response.accessToken,
        response.userID,
        response.email
      );

    if (!registrationResult.successful) {
      let errorString;
      switch (registrationResult.errorReasonCode) {
        case 'FB_EMAIL_ALREADY_ASSOCIATED_WITH_TH_ACCOUNT':
          errorString =
            'There is already an existing DAYMADE account using this email address.';
          break;

        default:
          errorString = registrationResult.reason;
      }

      setError(errorString);
      return;
    } else {
      if (registrationResult.token) {
        handleSuccessfulLogin(
          registrationResult.token,
          registrationResult.welcomeName
        );
        return;
      }
    }

    handleStartRegistration({
      facebookUserId: response.userID,
      email: response.email ?? '',
      firstName: response.first_name ?? '',
      lastName: response.last_name ?? '',
    });
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const isLoginRedirected =
      FacebookLoginClient.isRedirected(loginDialogProps);
    const isRegisterRedirected =
      FacebookLoginClient.isRedirected(registerDialogProps);

    isRedirected.current = isLoginRedirected || isRegisterRedirected;
    if (isRedirected.current) {
      setIsFacebookLoading(true);
      const loadProfile = async () => {
        const code = router.query.code;
        const redirect_uri = isLoginRedirected
          ? loginDialogProps.redirect_uri
          : registerDialogProps;
        const profileResponse = await getFacebookProfileFromCode(
          code,
          redirect_uri
        );

        if (!profileResponse.reason) {
          if (isLoginRedirected) {
            completeSignIn({
              accessToken: profileResponse.access_token,
              userID: profileResponse.id,
              email: profileResponse.email,
              first_name: profileResponse.first_name,
              last_name: profileResponse.last_name,
            });
          } else {
            completeRegistration({
              accessToken: profileResponse.access_token,
              userID: profileResponse.id,
              email: profileResponse.email,
              first_name: profileResponse.first_name,
              last_name: profileResponse.last_name,
            });
          }
        } else {
          console.log(
            'Facebook redirect login failed: ',
            profileResponse.reason
          );
        }
        setIsFacebookLoading(false);
      };

      loadProfile();
    }
  }, [router.isReady]);

  const doFacebookSignIn = () => {
    const loginOptions: LoginOptions = {
      scope: 'public_profile, email',
    };
    setIsFacebookLoading(true);

    if (isFacebookApp() || isAndroid) {
      FacebookLoginClient.redirectToDialog(loginDialogProps, loginOptions);
    } else {
      FacebookLoginClient.login((loginResponse) => {
        if (isRedirected.current) {
          console.log('Facebook login onSucccess aborted: redirected');
          return;
        }

        if (!loginResponse.authResponse) {
          console.log('Login Failed!', loginResponse.status);
          setIsFacebookLoading(false);

          return;
        }

        const authResponse = loginResponse.authResponse;

        FacebookLoginClient.getProfile(
          (res: any) => {
            setIsFacebookLoading(false);

            completeSignIn({
              accessToken: authResponse.accessToken,
              userID: authResponse.userID,
              email: res.email,
              first_name: res.first_name,
              last_name: res.last_name,
            });
          },
          { fields: 'id,email,first_name,last_name' }
        );
      }, loginOptions);
    }
  };

  const doFacebookRegister = () => {
    const loginOptions: LoginOptions = {
      scope: 'public_profile, email',
    };

    setIsFacebookLoading(true);

    if (isFacebookApp() || isAndroid) {
      FacebookLoginClient.redirectToDialog(registerDialogProps, loginOptions);
    } else {
      FacebookLoginClient.login((loginResponse) => {
        if (isRedirected.current) {
          console.log('Facebook login onSucccess aborted: redirected');
          return;
        }

        if (!loginResponse.authResponse) {
          setIsFacebookLoading(false);
          console.log('Login Failed!', loginResponse.status);
          return;
        }

        const authResponse = loginResponse.authResponse;

        FacebookLoginClient.getProfile(
          (res: any) => {
            setIsFacebookLoading(false);
            completeRegistration({
              accessToken: authResponse.accessToken,
              userID: authResponse.userID,
              email: res.email,
              first_name: res.first_name,
              last_name: res.last_name,
            });
          },
          { fields: 'id,email,first_name,last_name' }
        );
      }, loginOptions);
    }
  };

  return {
    isFacebookLoading,
    doFacebookSignIn,
    doFacebookRegister,
  };
};
