import React from 'react';
import { DataProvider } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';

export const WinnerGlobalContext = observer(
  ({ children }: React.PropsWithChildren) => {
    return (
      <DataProvider
        name="winners"
        data={{
          winners: []
        }}
      >
        {children}
      </DataProvider>
    );
  }
);
