import { ASSET_PREFIX } from "utils/constants"

interface ISliderButtons {
    leftButtonDisabled: boolean
    rightButtonDisabled: boolean
    slidePrevious: () => void
    slideNext: () => void
}

const styles = { width: "30px", padding: '4px', cursor:'pointer' }

export const SliderButtons = ({leftButtonDisabled, rightButtonDisabled, slidePrevious, slideNext}: ISliderButtons) => {
    return (
        <div className='invisible md:visible flex bg-cyan'>
            <img
                onClick={leftButtonDisabled ? undefined :() => slidePrevious()}
                src={`${ASSET_PREFIX}/images/arrow-${leftButtonDisabled ? 'grey' : 'black'}.png`} 
                alt={`arrow-left`} 
                style={styles}
            />
            <img 
                onClick={rightButtonDisabled ? undefined : () => slideNext()}
                src={`${ASSET_PREFIX}/images/arrow-${rightButtonDisabled ? 'grey' : 'black'}.png`} 
                alt={`arrow-right`} style={{ ...styles, transform: `rotate(180deg)` }}
            />
        </div>
    )
}