import React, { useEffect, useMemo, useState } from 'react';
import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';
import Router from 'next/router';

// Users will be able to set these props in Studio.
interface RootGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const RootGlobalContext = observer(
  ({ children }: React.PropsWithChildren<RootGlobalContextProps>) => {
    const RouterPush = (url: string) => {
      Router.push(url);
    };
    const actions = useMemo(
      () => ({
        RouterPush,
      }),
      []
    );

    return (
      <GlobalActionsProvider
        contextName="RootGlobalContext"
        actions={actions}
      >
        <DataProvider
          name="root"
          data={{}}
        >
          {children}
        </DataProvider>
      </GlobalActionsProvider>
    );
  }
);
