import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ASSET_PREFIX } from 'utils/constants';
import { SliderButtons } from './SliderButtons';
import { useIsMobile } from 'hooks/ui/useIsMobile';

const images = [
    {
      id: 0,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-1.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-1-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-1-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-1-thumbnail-small.webp`,
      seoText: `I don't know if I can pick a favourite part. We loved every moment! - Harriet G won a Japan trip for 2 worth £25,000`
    },
    {
      id: 1,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-2.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-2-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-2-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-2-thumbnail-small.webp`,
      seoText: 'We had a great time in the Marrakech madness. Thank you so much - Neil W won a trip for 2 to Marrakech worth £2,000'
    },
    {
      id: 2,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-31.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-31-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-31-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-31-thumbnail-small.webp`,
      seoText: `Never thought I'd get lucky winning anything. Thank you again! -  Aaron S won a Gadget Bundle worth £2,000`
    },
    {
      id: 3,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-4.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-4-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-4-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-4-thumbnail-small.webp`,
      seoText: "Fantastic day at IKEA... thanks so much - Lucy B won an IKEA gift card worth £150"
    },
    {
      id: 4,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-5.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-5-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-5-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-5-thumbnail-small.webp`,
      seoText: "We've had the BEST time. Thank you so much! - Nicola R won a trip for 2 to Barbados worth £2,000"
    },
    {
      id: 5,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-6.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-6-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-6-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-6-thumbnail-small.webp`,
      seoText: 'Thank you for giving so many people like me chances to visit these beautiful places - Lucie Z won a trip for 2 to Tulum worth £2,000'
    },
    {
      id: 6,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-7.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-7-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-7-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-7-thumbnail-small.webp`,
      seoText: "I just want to thank Daymade for the prize. Pure enjoyment at its best - Doreen N won a 2-night London trip for 2 worth £150"
    },
    {
      id: 7,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-8.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-8-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-8-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-8-thumbnail-small.webp`,
      seoText: "The trip was absolutely amazing. Zoe and I had the best time in Iceland. Truly unforgettable - James C won a trip for 2 to Iceland worth £10,000"
    },
    {
      id: 8,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-9.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-9-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-9-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-9-thumbnail-small.webp`,
      seoText: 'We have never been able to afford to take our kids abroad on holiday so this is literally life changing - Annie L won a lastminute.com gift card worth £10,000'
    },
    {
      id: 9,
      main: `${ASSET_PREFIX}/images/winners/carousel/image-10.webp`,
      small: `${ASSET_PREFIX}/images/winners/carousel/image-10-small.webp`,
      thumbnail: `${ASSET_PREFIX}/images/winners/carousel/image-10-thumbnail.webp`,
      thumbnail_small: `${ASSET_PREFIX}/images/winners/carousel/image-10-thumbnail-small.webp`,
      seoText: 'Think this is a brilliant and genuine site. Highly recommended - Lynda J won a bouquet of flowers worth £25'
    }
];

const imageContainerStyle: React.CSSProperties = {
  width: '100%',  
};

const thumbnailContainerStyle: React.CSSProperties = {
  cursor: 'pointer',
};

const carouselContainerStyle: React.CSSProperties = {
    margin: '0 auto',
    width: '100%',
    height: '100%'
};

const AUTOPLAY_SPEED = 5000 
  
export const CarouselSlider: React.FC = () => {
    const { isMobile } = useIsMobile()

    const [nav1, setNav1] = useState<Slider | null>(null);
    const [nav2, setNav2] = useState<Slider | null>(null);
    const sliderRef1 = useRef<Slider>(null);
    const sliderRef2 = useRef<Slider>(null);

    const [activeSlide, setActiveSlide] = useState(0);

    useEffect(() => {
        if (sliderRef1.current && sliderRef2.current) {
          setNav1(sliderRef1.current);
          setNav2(sliderRef2.current);
        }
    }, []);

    const settingsMain = {
        asNavFor: sliderRef2.current ?? undefined,
        ref: sliderRef2,
        dots: false,
        arrows: false,
        infinite: true,
        autoplaySpeed: AUTOPLAY_SPEED,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        beforeChange: (_current: any, next: any) => {
          setActiveSlide(next);
        },
    };
    

    const settingsThumbs = {
        asNavFor: sliderRef2.current ?? undefined,
        ref: sliderRef2,
        dots: true,
        arrows: false,
        infinite: true,
        autoplaySpeed: AUTOPLAY_SPEED,
        slidesToShow: images.length,
        slidesToScroll: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: images.length,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
        }
        ]
    };

    const slidePrevious = () => {
      sliderRef1.current?.slickPrev()
    }

    const slideNext = () => {
      sliderRef1.current?.slickNext()
    }

    return (
        <div style={carouselContainerStyle}>
          <div className='w-full flex justify-end'>
            <SliderButtons 
              leftButtonDisabled={activeSlide === 0} 
              rightButtonDisabled={activeSlide === images.length - 1}
              slideNext={slideNext}
              slidePrevious={slidePrevious}
            />
          </div>
          <Slider {...settingsMain} asNavFor={nav2 as Slider} ref={sliderRef1}>
              {images.map((image, idx) => (
                <div key={image.id} style={imageContainerStyle}>
                    <img src={!isMobile ? image.main : image.small} alt={image.seoText} style={{ width: "100%", height: '100%', padding: '4px' }} />
                </div>
              ))}
          </Slider>
          <Slider {...settingsThumbs}
              asNavFor={nav1 as Slider}
              ref={sliderRef2}
          >
              {images.map((image, idx) => (
                <div key={image.id} style={thumbnailContainerStyle}>
                    <img 
                      src={!isMobile ? image.thumbnail : image.thumbnail_small} 
                      alt={image.seoText}
                      style={{
                        width: "100%", 
                        height: '100%', 
                        padding: '4px 4px 0px 4px',
                        opacity: activeSlide === idx ? 0.5 : 1

                      }} 
                    />
                </div>
              ))}
          </Slider>
        <style>{`
            .slick-prev:before, .slick-next:before {
            color: black;
            }
        `}</style>
        </div>
    );
};