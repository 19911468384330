import Slider from "react-slick";
import { ASSET_PREFIX } from "utils/constants";
import { SliderButtons } from "./SliderButtons";
import { useRef, useState } from "react";


const imageContainerStyle: React.CSSProperties = {
    width: '100%',
};
  
const container: React.CSSProperties = {
    margin: '0 auto',
    width: '100%',
    height: '100%'
};


const images = [
  {
    id: 0,
    main: `${ASSET_PREFIX}/images/winners/slider/image-1.webp`,
    seoText: `I don't know if I can pick a favourite part. We loved every moment! - Harriet G won a Japan trip for 2 worth £25,000`
  },
  {
    id: 1,
    main: `${ASSET_PREFIX}/images/winners/slider/image-21.webp`,
    seoText: 'We had a great time in the Marrakech madness. Thank you so much - Neil W won a trip for 2 to Marrakech worth £2,000'
  },
  {
    id: 2,
    main: `${ASSET_PREFIX}/images/winners/slider/image-3.webp`,
    seoText: `Never thought I'd get lucky winning anything. Thank you again! -  Aaron S won a Gadget Bundle worth £2,000`

  },
  {
    id: 3,
    main: `${ASSET_PREFIX}/images/winners/slider/image-4.webp`,
    seoText: "Fantastic day at IKEA... thanks so much - Lucy B won an IKEA gift card worth £150"

  },
  {
    id: 4,
    main: `${ASSET_PREFIX}/images/winners/slider/image-5.webp`,
    seoText: "We've had the BEST time. Thank you so much! - Nicola R won a trip for 2 to Barbados worth £2,000"

  },
  {
    id: 5,
    main: `${ASSET_PREFIX}/images/winners/slider/image-6.webp`,
    seoText: 'Thank you for giving so many people like me chances to visit these beautiful places - Lucie Z won a trip for 2 to Tulum worth £2,000'
  },
  {
    id: 6,
    main: `${ASSET_PREFIX}/images/winners/slider/image-7.webp`,
    seoText: "I just want to thank Daymade for the prize. Pure enjoyment at its best - Doreen N won a 2-night London trip for 2 worth £150"
  },
  {
    id: 7,
    main: `${ASSET_PREFIX}/images/winners/slider/image-8.webp`,
    seoText: "The trip was absolutely amazing. Zoe and I had the best time in Iceland. Truly unforgettable - James C won a trip for 2 to Iceland worth £10,000"
  },
  {
    id: 8,
    main: `${ASSET_PREFIX}/images/winners/slider/image-9.webp`,
    seoText: 'We have never been able to afford to take our kids abroad on holiday so this is literally life changing - Annie L won a lastminute.com gift card worth £10,000'
  },
]

const AUTOPLAY_SPEED = 6000 

export const CardSlider: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef1 = useRef<Slider>(null);

  const settingsMain = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplaySpeed: AUTOPLAY_SPEED,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    beforeChange: (_current: any, next: any) => {
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const slidePrevious = () => {
    sliderRef1.current?.slickPrev()
  }

  const slideNext = () => {
    sliderRef1.current?.slickNext()
  }


  return (
      <div style={container}>
        <div className='w-full flex justify-end'>
          <SliderButtons 
            leftButtonDisabled={activeSlide === 0} 
            rightButtonDisabled={activeSlide === images.length - 1}
            slideNext={slideNext}
            slidePrevious={slidePrevious}
          />
        </div>
          <Slider ref={sliderRef1} {...settingsMain}>
              {images.map((image) => (
              <div key={image.id} style={imageContainerStyle}>
                  <img src={image.main} alt={image.seoText} style={{ width: "100%", padding: '4px' }} />
              </div>
              ))}
          </Slider>
      </div>
  )
}
