import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { useDrawStore } from 'hooks/stores/useDrawStore';
import { observer } from 'mobx-react-lite';
import useScript from 'utils/useScript';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useRouter } from 'next/router';
import { APP_ENDPOINT } from 'utils/constants';
import { useAppleSignIn } from './authentication/useAppleSignIn';
import { useGoogleSignIn } from './authentication/useGoogleSignIn';
import { useFacebookSignIn } from './authentication/useFacebookSignIn';
import { getMe, login, register } from 'utils/api/auth';
import { identifyUser, trackEvent } from 'utils/analytics/analytics';
import { useUIStore } from 'hooks/stores/useUIStore';
import { useUserStore } from 'hooks/stores/useUserStore';
import { IHandleStartRegistrationProps } from 'types/ui/Registration';
import { useEmailSignIn } from './authentication/useEmailSignIn';
import * as Sentry from '@sentry/browser';

import {
  validateInput,
  validDateOfBirth,
  validEmail,
  validName,
  validPassword,
  validPhoneNumber,
} from 'utils/RegistrationValidator';

import { getReferrerDetails } from 'utils/common';
import { getCookie } from 'utils/cookie';
import dayjs from 'dayjs';
import { debounce } from 'throttle-debounce';
import { isFacebookApp } from 'utils/ua';
import { useIsMobile } from 'hooks/ui/useIsMobile';

// Users will be able to set these props in Studio.
interface AuthenticationGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const AuthenticationGlobalContext = observer(
  ({ children }: React.PropsWithChildren<AuthenticationGlobalContextProps>) => {
    const router = useRouter();

    const { isMobile } = useIsMobile();

    const { setCurrentUser, currentUser } = useUserStore();
    const { displayToast } = useUIStore();

    const [error, setError] = useState<string>();
    const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
    const [email, setEmail] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [googleUserId, setGoogleUserId] = useState<string>();
    const [facebookUserId, setFacebookUserId] = useState<string>();
    const [appleUserId, setAppleUserId] = useState<string>();

    const [invitedBy, setInvitedBy] = useState<{
      referralId: string;
      name: string;
    }>();
    const [dobDay, setDobDay] = useState('');
    const [dobMonth, setDobMonth] = useState('');
    const [dobYear, setDobYear] = useState('');
    const [gender, setGender] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [agreedToMarketing, setAgreedToMarketing] = useState(true);
    const [agreedToTerms, setAgreedToTerms] = useState(true);

    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
    const [guestEmailAddress, setGuestEmailAddress] = useState<string>();

    const [hideGoogleLogin, setHideGoogleLogin] = useState(false);
    const [hideAppleLogin, setHideAppleLogin] = useState(false);


    const isSocialRegistration = !!(
      facebookUserId ||
      googleUserId ||
      appleUserId
    );

    const { catchall } = router.query ?? {};

    const plasmicPath =
      typeof catchall === 'string'
        ? catchall
        : Array.isArray(catchall)
        ? `/${catchall.join('/')}`
        : '/';

    let redirectUrl =
      typeof router.query.redirect == 'string'
        ? router.query.redirect
        : router.query.redirect?.[0];

    const isAuthPage =
      router.pathname === '/[[...catchall]]' &&
      ['/login-2', '/register-2', '/enternow/5'].includes(plasmicPath);

    useEffect(() => {
      if (isAuthPage) {
        if (currentUser) {
          if (redirectUrl) {
            router.replace(redirectUrl);
          } else {
            router.replace('/account');
          }
        }

      }
    }, [currentUser, redirectUrl]);


    useEffect(() => {
      if (!router.isReady) return;
      const result = isFacebookApp(isMobile);

      setHideGoogleLogin(result);
      setHideAppleLogin(result);
    }, [router.isReady, isMobile]);

    useEffect(() => {
      (async () => {
        if (!invitedBy) {
          const result = await getReferrerDetails();
          setInvitedBy(result);
        }
        const storedGuestEmailAddress =
          typeof localStorage !== 'undefined' &&
          localStorage.getItem('guest_email_address');
        setGuestEmailAddress(storedGuestEmailAddress || undefined);
        setEmail(storedGuestEmailAddress || '');
      })();
    }, []);

    const updateEmail = debounce(500, (value: string) => {
      setEmail(value);

      const _isValidEmail = validEmail(value);
      setIsValidEmail(_isValidEmail);
      if (!showEmailForm && _isValidEmail) {
        setShowEmailForm(true);
      }
    });
    const updatePassword = debounce(500, (value: string) => {
      setPassword(value);

      setIsValidPassword(validPassword(value));
    });

    const submitRegistration = async () => {
      const utmParams = localStorage.getItem('utm_params');
      const overrideReferralScheme = getCookie('override_referral_scheme');

      const paddedDobDay = dobDay.length === 1 ? `0${dobDay}` : dobDay;
      const paddedDobMonth = dobMonth.length === 1 ? `0${dobMonth}` : dobMonth;

      const needPassword = !isSocialRegistration;

      const errors: Record<string, string> = {};

      // First name.
      if (!validName(firstName)) {
        errors['firstName'] = 'Please provide a valid first name.';
      }

      // Last name.
      if (!validName(lastName)) {
        errors['lastName'] = 'Please provide a valid last name.';
      }

      if (!email || !validEmail(email)) {
        errors['email'] = 'Please provide a valid email address.';
      }

      if (needPassword && (!password || !validPassword(password))) {
        errors['password'] =
          'Please create a password with at least 8 characters and a number/special character.';
      }

      if (!agreedToTerms) {
        errors['agreedToTerms'] =
          'You must agree to our Terms & Conditions and Privacy Policy.';
      }

      if (Object.keys(errors).length > 0) {
        setFieldErrors(errors);
        return;
      } else {
        setFieldErrors({});
      }

      const dob = validDateOfBirth(
        dobDay.length === 1 ? `0${dobDay}` : dobDay,
        dobMonth.length === 1 ? `0${dobMonth}` : dobMonth,
        dobYear
      )
        ? dayjs(
            `${dobYear}-${paddedDobMonth}-${paddedDobDay}T12:00:00`
          ).toISOString()
        : null;

      doEmailRegister(
        firstName!,
        lastName!,
        email!,
        phoneNumber,
        password,
        invitedBy?.referralId || null,
        agreedToMarketing,
        utmParams,
        facebookUserId,
        googleUserId,
        appleUserId,
        overrideReferralScheme,
        dob,
        gender
      );
    };

    const submitEmailLogin = async () => {
      const errors: Record<string, string> = {};

      if (!email || !validEmail(email)) {
        errors['email'] = 'Please provide a valid email address.';
      }

      if (!password || !validPassword(password)) {
        errors['password'] =
          'Passwords have at least 8 characters and a number/special character.';
      }

      if (Object.keys(errors).length > 0) {
        setFieldErrors(errors);
        return;
      } else {
        setFieldErrors({});
      }

      doEmailSignIn(email!, password);
    };

    const handleSuccessfulLogin = async (
      token: string,
      welcomeName: string
    ) => {
      const me = await getMe(token);

      setCurrentUser(me);
      trackEvent('Login');
      identifyUser({
        id: me.id,
        emailAddress: me.email_address,
        phoneNumber: me.phone_number,
        firstName: me.first_name,
        lastName: me.last_name,
      });
      // displayToast({
      //   title: welcomeName
      //     ? `Welcome back to DAYMADE, ${welcomeName}!`
      //     : 'Welcome back to DAYMADE!',
      //   timeout: 3500,
      // });
    };

    const handleSuccessfulRegistration = async (
      token: string,
      welcomeName: string
    ) => {
      const me = await getMe(token);

      setCurrentUser(me);
      identifyUser({
        id: me.id,
        emailAddress: me.email_address,
        phoneNumber: me.phone_number,
        firstName: me.first_name,
        lastName: me.last_name,
      });
      // displayToast({
      //   title: `Welcome to DAYMADE, ${welcomeName}!`,
      //   timeout: 3500,
      // });
      localStorage.removeItem('guest_email_address');
    };

    const handleStartRegistration = async (
      props: IHandleStartRegistrationProps
    ) => {
      setEmail(props.email);
      setFirstName(props.firstName);
      setLastName(props.lastName);

      if (props.googleUserId) {
        setGoogleUserId(props.googleUserId);
      }

      if (props.facebookUserId) {
        setFacebookUserId(props.facebookUserId);
      }

      if (props.appleUserId) {
        setAppleUserId(props.appleUserId);
      }
    };

    const { isEmailLoading, doEmailSignIn, doEmailRegister } = useEmailSignIn(
      handleSuccessfulLogin,
      handleSuccessfulRegistration,
      setError
    );

    const { isAppleLoading, doAppleSignIn, doAppleRegister } = useAppleSignIn(
      handleSuccessfulLogin,
      handleStartRegistration,
      setError
    );
    const { isGoogleLoading, doGoogleSignIn, doGoogleRegister } =
      useGoogleSignIn(handleSuccessfulLogin, handleStartRegistration, setError);

    const { isFacebookLoading, doFacebookSignIn, doFacebookRegister } =
      useFacebookSignIn(
        handleSuccessfulLogin,
        handleStartRegistration,
        setError
      );

    const actions = {
      updateEmail,
      updatePassword,
      setFirstName,
      setLastName,
      setAgreedToMarketing,
      setAgreedToTerms,
      submitEmailLogin,
      submitRegistration,
      doGoogleSignIn,
      doGoogleRegister,
      doAppleSignIn,
      doAppleRegister,
      doFacebookSignIn,
      doFacebookRegister,
    };

    return (
      <GlobalActionsProvider
        contextName="AuthFlowGlobalContext"
        actions={actions}
      >
        <DataProvider
          name="AuthFlow"
          data={{
            isEmailLoading,
            isGoogleLoading,
            isAppleLoading,
            isFacebookLoading,
            showEmailForm,
            isValidEmail,
            isValidPassword,
            email,
            password,
            firstName,
            lastName,
            googleUserId,
            facebookUserId,
            appleUserId,
            error,
            fieldErrors,
            hideGoogleLogin,
            hideAppleLogin,
            isSocialRegistration,
            agreedToMarketing,
            agreedToTerms,
            redirectUrl,
          }}
        >
          {children}
        </DataProvider>
      </GlobalActionsProvider>
    );
  }
);
