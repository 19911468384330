import React, { useEffect } from 'react';
import { DataProvider } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';
import { usePrizeStore } from 'hooks/stores/usePrizeStore';
import { useUTMStore } from 'hooks/stores/useUTMStore';
import { doABTestWithDistribution } from 'utils/analytics/abtest';

// Users will be able to set these props in Studio.
interface PrizeGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const PrizeGlobalContext = observer(
  ({ children }: React.PropsWithChildren<PrizeGlobalContextProps>) => {
    const {
      trendingPrizes,
      selectedLandingPagePrize,
      setLandingPagePrizeFromUTMContent,
    } = usePrizeStore();
    const { utmParams } = useUTMStore() 

    useEffect(() => {
      (async () => {
        if (utmParams.utm_content) {
          const testGroup = doABTestWithDistribution(
            'DYNAMIC_LANDING_TEST',
            [
              { choice: 'CONTROL', weight: 1 },
              { choice: 'TEST', weight: 1 },
            ]
          );

          if (testGroup === 'CONTROL'){
            return;
          }
          
          setLandingPagePrizeFromUTMContent(utmParams.utm_content);
        }
      })();
    }, []);

    return (
      <DataProvider
        name="prizes"
        data={{
          trendingPrizes,
          selectedLandingPagePrize,
        }}
      >
        {children}
      </DataProvider>
    );
  }
);
