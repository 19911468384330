import React, { useEffect, useMemo, useState } from 'react';
import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { useDrawStore } from 'hooks/stores/useDrawStore';
import { observer } from 'mobx-react-lite';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useSubscriberStatus } from 'hooks/useSubscriberStatus';

// Users will be able to set these props in Studio.
interface UserGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const UserGlobalContext = observer(
  ({ children }: React.PropsWithChildren<UserGlobalContextProps>) => {
    const { eligibleForIntroOffer, eligibleForUpgrade, currentUser } =
      useUserStore();
    const { subscriberStatus, isPremiumSubscriber } =
      useSubscriberStatus(currentUser);

    return (
      <DataProvider
        name="user"
        data={{
          currentUser,
          eligibleForIntroOffer,
          eligibleForUpgrade,
          subscriberStatus,
          isPremiumSubscriber,
        }}
      >
        {children}
      </DataProvider>
    );
  }
);
