import React, { useEffect } from 'react';
import { DataProvider } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';
import { useUIStore } from 'hooks/stores/useUIStore';
import { useRouter } from 'next/router';
import { calculateProgressInfo, getMultistepPaths, getMultistepV2TestGroup } from 'utils/ab-test/multistep/MultistepV2';
import { MultistepStandardPaths, MultistepTestPaths } from 'types/ui/EnterFlowProgress';

interface UIGlobalContextProps {}

export const UIGlobalContext = observer(
  ({ children }: React.PropsWithChildren<UIGlobalContextProps>) => {
    const router = useRouter();


    const { enterFlowProgress, setEnterFlowProgress } = useUIStore();

    useEffect(() => {

        const currentPath = router.asPath.split('?')[0];

        if (
          ![...MultistepStandardPaths, ...MultistepTestPaths].includes(
            currentPath
          )
        ) {
          return;
        }

        const testGroup = getMultistepV2TestGroup();

        const pathsArray = getMultistepPaths(testGroup);

        const { currentStep, totalSteps } = calculateProgressInfo(
          currentPath,
          pathsArray
        );
        
        setEnterFlowProgress({ currentStep, totalSteps });
    }, [router.asPath]);

    return (
      <DataProvider
        name="ui"
        data={{
          enterFlowProgress,
        }}
      >
        {children}
      </DataProvider>
    );
  }
);
