import { useEffect, useState } from 'react';
import { IHandleStartRegistrationProps } from 'types/ui/Registration';
import {
  loginWithApple,
  validateAppleIdTokenAndEmailForRegistration,
} from 'utils/api/auth';
import { APP_ENDPOINT } from 'utils/constants';
import useScript from 'utils/useScript';

export const useAppleSignIn = (
  handleSuccessfulLogin: (token: string, name: string) => Promise<void>,
  handleStartRegistration: (
    props: IHandleStartRegistrationProps
  ) => Promise<void>,
  setError: (error?: string) => void
) => {
  const [isAppleLoading, setisAppleLoading] = useState<boolean>(false);

  const [scriptLoaded] = useScript(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
  );
  useEffect(() => {
    if (scriptLoaded) {
      // @ts-ignore
      window.AppleID?.auth.init({
        clientId: 'uk.co.daymade.signin',
        scope: 'name email',
        redirectURI: `https://${APP_ENDPOINT}/register`,
        usePopup: true,
      });
    }
  }, [scriptLoaded]);

  const doAppleSignIn = async () => {
    setisAppleLoading(true);

    try {
      // @ts-ignore
      const data = await window.AppleID?.auth.signIn();
      console.log('Apple login success:', data);

      const loginResponse = await loginWithApple(data.authorization.id_token);

      if (loginResponse.successful) {
        await handleSuccessfulLogin(
          loginResponse.token,
          loginResponse.welcomeName
        );
      } else {
        setError(loginResponse.reason);
      }
    } catch (err) {
      console.error('Apple login error:', err);
    }
    setisAppleLoading(false);
  };

  const doAppleRegister = async () => {
    setisAppleLoading(true);

    try {
      // @ts-ignore
      const data = await window.AppleID?.auth.signIn();

      const appleIdToken = data.authorization.id_token;
      const result = await validateAppleIdTokenAndEmailForRegistration(
        appleIdToken
      );
      const validatedAppleUserId = result.appleUserId;

      if (!result.successful) {
        setError(result.reason);
        console.error(result.errorReasonCode);
        return;
      } else {
        if (result.token) {
          handleSuccessfulLogin(result.token, result.welcomeName);
          return;
        }
      }

      handleStartRegistration({
        appleUserId: validatedAppleUserId,
        email: result.appleEmailAddress ?? '',
        firstName: data.user.name.firstName ?? '',
        lastName: data.user.name.lastName ?? '',
      });

      console.log('Apple login success:', data);
    } catch (err) {
      console.error('Apple login error:', err);
    }
    setisAppleLoading(false);
  };

  return { isAppleLoading, doAppleSignIn, doAppleRegister };
};
