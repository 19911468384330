import React from 'react';
import { DataProvider, } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';
import { useConfirmationData } from 'hooks/confirmation/useConfirmationData';

// Users will be able to set these props in Studio.
interface ConfirmationGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const ConfirmationGlobalContext = observer(
  ({ children }: React.PropsWithChildren<ConfirmationGlobalContextProps>) => {
    const {
      loading,
      orderId,
      liteConfirmationPage,
      freeConfirmationPage,
      tuesdayDraw,
      fridayDraw,
      drawDates,
      entries,
      usedFreeSubscriptionDeal,
      promoCodeEntries,
      subscriptionEnabled,
      referralThreshold,
      orderCostMicro,
      subscriptionCosts,
      monthlyPayments,
    } = useConfirmationData();

    const parseEntries =  ((inputString: string) => {
      // Split the input string by the '|' character
      const [mainPart, bonusPart] = inputString.split('|');
      
      // Split the main part by the ',' character
      const mainNumbers = mainPart.split(',');
  
      // Create the object with the desired structure
      const result = {
          main1: mainNumbers[0],
          main2: mainNumbers[1],
          main3: mainNumbers[2],
          main4: mainNumbers[3],
          bonus: bonusPart
      }
  
      return result;
    })

    return (
      <DataProvider
        name="confirmation"
        data={{
          loading,
          orderId,
          liteConfirmationPage,
          freeConfirmationPage,
          tuesdayDraw,
          fridayDraw,
          drawDates,
          entries: entries && entries.map(entry => parseEntries(entry)),
          usedFreeSubscriptionDeal,
          promoCodeEntries,
          subscriptionEnabled,
          referralThreshold,
          orderCostMicro,
          subscriptionCosts,
          monthlyPayments,
        }}
      >
        {children}
      </DataProvider>
    );
  }
);
