import React, { useMemo } from 'react';
import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';
import { useEnterStore } from 'hooks/stores/useEnterStore';
import { goUpgradeOffer } from './enter/upsell/goUpgrade';

interface EnterFlowGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const EnterFlowGlobalContext = observer(
  ({ children }: React.PropsWithChildren<EnterFlowGlobalContextProps>) => {
    const {
      entryMethod,
      entryMethodBundles,
      num_entries,
      lines,
      days,
      boosted,
      discountFirstEntry,
      promo_code,
      promoCodeApplicationResult,
      discountMultiplier,
      discountMicroAmount,
      discountBannerText,
      regularOrderCost,
      payNowCost,
      orderError,
      orderId,
      isValid,
      isReferralFlow,
      promoCodeMessages,
      setEntryMethod,
      setNumEntries,
      setLines,
      removeLine,
      setLineNumber,
      setDays,
      toggleDay,
      setBoosted,
      setPromoCode,
      toggleLineNumber,
      toggleLineBonusNumber,
      generateLineRandomNumbers,
      chooseNumbers,
      navigateToEnterTest,
      navigateToCheckout,
    } = useEnterStore();

    // Compute the discount banner text based on the current state
    const computedDiscountBannerText = isReferralFlow
      ? '4 weeks free'
      : discountBannerText
        ? discountBannerText
        : discountFirstEntry
          ? '50% off 1st entry'
          : null;

    // Memoize actions to prevent unnecessary re-renders
    const actions = useMemo(
      () => ({
        setEntryMethod,
        setNumEntries,
        setLines,
        removeLine,
        setLineNumber,
        setDays,
        toggleDay,
        setBoosted,
        setPromoCode,
        toggleLineNumber,
        toggleLineBonusNumber,
        generateLineRandomNumbers,
        goUpgradeOffer,
        chooseNumbers,
        navigateToEnterTest,
        navigateToCheckout,
      }),
      [
        setEntryMethod,
        setNumEntries,
        setLines,
        removeLine,
        setLineNumber,
        setDays,
        toggleDay,
        setBoosted,
        setPromoCode,
        toggleLineNumber,
        toggleLineBonusNumber,
        generateLineRandomNumbers,
        chooseNumbers,
        navigateToEnterTest,
        navigateToCheckout,
      ]
    );

    // Prepare the data to be provided to the context
    const data = {
      entryMethod,
      num_entries,
      lines,
      days,
      boosted,
      entryMethodBundles,
      promo_code,
      promoCodeApplicationResult,
      promoCodeMessages,
      discountMultiplier,
      discountMicroAmount,
      discountBannerText: computedDiscountBannerText,
      regularOrderCost,
      payNowCost: isReferralFlow ? 0 : payNowCost,
      orderError,
      orderId,
      isValid,
      isReferralFlow,
    };

    return (
      <GlobalActionsProvider
        contextName="EnterFlowGlobalContext"
        actions={actions}
      >
        <DataProvider name="enterFlow" data={data}>
          {children}
        </DataProvider>
      </GlobalActionsProvider>
    );
  }
);
