import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import React, { useEffect } from "react";
import { observer } from 'mobx-react-lite';
import { useScrollStore } from 'hooks/stores/useScrollStore';
import { useUIStore } from 'hooks/stores/useUIStore';

export const ScrollGlobalContext = observer(
  ({ children }: React.PropsWithChildren) => {
    const { isAboveThreshold } = useScrollStore() 

    return (
      <DataProvider
        name="scroll"
        data={{
          isAboveThreshold
        }}
      >
        {children}
      </DataProvider>
    );
  }
);