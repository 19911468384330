import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { IHandleStartRegistrationProps } from 'types/ui/Registration';
import {
  loginWithGoogle,
  validateGoogleAccessTokenAndEmailForRegistration,
} from 'utils/api/auth';

export const useGoogleSignIn = (
  handleSuccessfulLogin: (token: string, name: string) => Promise<void>,
  handleStartRegistration: (
    props: IHandleStartRegistrationProps
  ) => Promise<void>,
  setError: (error?: string) => void
) => {
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const onGoogleLogin = async (
    response: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>
  ) => {
    const loginResponse = await loginWithGoogle(response.access_token);
    if (loginResponse.successful) {
      await handleSuccessfulLogin(
        loginResponse.token,
        loginResponse.welcomeName
      );
    } else {
      console.log('google login failed', loginResponse.reason);

      setError(loginResponse.reason);
    }
  };

  const onGoogleRegister = async (
    response: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>
  ) => {
    setIsGoogleLoading(false);

    const validateRes = await validateGoogleAccessTokenAndEmailForRegistration(
      response.access_token
    );

    if (!validateRes.successful) {
      setError(validateRes.reason);
      return;
    } else {
      if (validateRes.token) {
        // found an account so logging in
        handleSuccessfulLogin(validateRes.token, validateRes.welcomeName);
        return;
      }
    }

    const googleResponse = await fetch(
      'https://www.googleapis.com/oauth2/v3/userinfo',
      {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
        },
      }
    );
    const userDetails = await googleResponse.json();

    handleStartRegistration({
      googleUserId: validateRes.googleUserId,
      email: userDetails.email ?? '',
      firstName: userDetails.given_name ?? '',
      lastName: userDetails.family_name ?? '',
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onGoogleLogin,
    onError: (...props) => {
      setIsGoogleLoading(false);
      console.log(props);
    },
    onNonOAuthError: (...props) => {
      setIsGoogleLoading(false);
      console.log(props);
    },
  });

  const googleRegister = useGoogleLogin({
    onSuccess: onGoogleRegister,
    onError: (...props) => {
      setIsGoogleLoading(false);
      console.log(props);
    },
    onNonOAuthError: (...props) => {
      setIsGoogleLoading(false);
      console.log(props);
    },
  });

  const doGoogleSignIn = async () => {
    setIsGoogleLoading(true);
    setError(undefined);
    await googleLogin();
  };

  const doGoogleRegister = async () => {
    setIsGoogleLoading(true);
    setError(undefined);
    await googleRegister();
  };

  return { isGoogleLoading, doGoogleSignIn, doGoogleRegister };
};
