import ChangeEmailPopup from "components/enter/ChangeEmailPopup";
import { useState } from "react";
import { ASSET_PREFIX } from "utils/constants"

interface IEmailConfirmationBanner { 
    emailAddress: string
    bannerColor?: string
    textSize?: string
    imageSize?: string
}

const defaultTextSize = '1em'

export const EmailConfirmationBanner = ({emailAddress, bannerColor, textSize, imageSize}: IEmailConfirmationBanner ) => {
    const [showChangeEmailPopup, setShowChangeEmailPopup] = useState(false);

    return (
        <div className="w-full">
            {showChangeEmailPopup && <ChangeEmailPopup
                // @ts-ignore:next-line
                currentEmailAddress={emailAddress}
                onClose={() => setShowChangeEmailPopup(false)}
            />}
            <div
                style={{ 
                    background: bannerColor ? bannerColor : '#FFCFB5', 

                }}
                className="py-5 md:py-7 gap-4 md:gap-4 w-full flex justify-center"
            >
                <div className="flex justify-center items-end flex-col">
                    <img
                        src={`${ASSET_PREFIX}/images/account/email-preferences.svg`}
                        style={{
                            width: imageSize ? imageSize : undefined,
                            height: imageSize ? imageSize : undefined,
                        }}
                    />
                </div>
                <div>
                    <p className="m-0" style={{
                        fontSize: textSize ? textSize : defaultTextSize

                    }}>
                        We'll send confirmation to{' '}
                        <strong id="GTM-email" className="italic font-extrabold">
                        {emailAddress}
                        </strong>{' '}
                    </p>
                    <p className="!text-start italic font-extrabold m-0" style={{
                        fontSize: textSize ? textSize : defaultTextSize
                    }}>
                        Not correct?{' '}
                        <span
                        className="underline hover:cursor-pointer"
                        onClick={() => setShowChangeEmailPopup(true)}
                        >
                        Change email
                        </span>
                    </p>
                </div>
            </div>
        </div>
)
}