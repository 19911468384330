import React from 'react';
import { DataProvider } from '@plasmicapp/host';
import { observer } from 'mobx-react-lite';
import { useActivityFeedStore } from 'hooks/stores/useActivityFeedStore';

// Users will be able to set these props in Studio.
interface ActivityFeedGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const ActivityFeedGlobalContext = observer(
  ({ children }: React.PropsWithChildren<ActivityFeedGlobalContextProps>) => {
    const { activityFeedItems } = useActivityFeedStore();

    return (
      <DataProvider
        name="activity_feed"
        data={{
          activityFeedItems,
        }}
      >
        {children}
      </DataProvider>
    );
  }
);
