import React, { useContext, useState } from 'react';
import GenericModal from 'components/GenericModal';
import { ASSET_PREFIX } from 'utils/constants';
import RoundedButton from 'components/RoundedButton';
import { validEmail } from 'utils/RegistrationValidator';
import { getMe, updateEmailAddress } from 'api/auth';
import { getCookie } from 'utils/cookie';
import { Message } from 'semantic-ui-react';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';

interface IChangeEmailPopup {
  currentEmailAddress: string
  onClose: () => void
}

const ChangeEmailPopup = observer(({ currentEmailAddress, onClose }: IChangeEmailPopup) => {
  const { displayToast } = useUIStore();
  const { currentUser, setCurrentUser, setCurrentGuestUser } = useUserStore();

  const [newEmailAddressText, setNewEmailAddressText] = useState('');
  const [ctaLoading, setCtaLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!currentEmailAddress) return null;

  return (
    <GenericModal onClose={onClose}>
      <img
        src={`${ASSET_PREFIX}/images/enter/change-email-icon.png`}
        alt=""
        style={{
          width: 80,
          height: 80,
        }}
      />

      <p style={{ marginTop: '1em', fontWeight: 700, fontSize: '1.7em' }}>
        Change email
      </p>

      <input
        type="text"
        autoComplete="username"
        autoCorrect="off"
        value={currentEmailAddress}
        className="emailField"
        disabled
        style={{
          color: '#777',
        }}
      />

      <input
        placeholder="New email address"
        type="text"
        autoComplete="username"
        autoCorrect="off"
        onChange={(e) => {
          setNewEmailAddressText(e.target.value);
          setError(null);
        }}
        value={newEmailAddressText}
        className="emailField"
      />

      {error && <Message error>{error}</Message>}

      <RoundedButton
        onClick={async () => {
          const email = newEmailAddressText.toLowerCase().trim();

          setError(null);
          setCtaLoading(true);

          // First, check that the email is valid
          if (!validEmail(email)) {
            setError(`"${email}" is not a valid email address.`);
            setCtaLoading(false);
            return;
          }

          const jwt = getCookie('jwt');
          const result = await updateEmailAddress(jwt, email);
          setCtaLoading(false);

          if (result.successful) {
            displayToast({
              title: `Successfully updated email address to ${email}`,
              color: '#00ff00',
              timeout: 7000,
            });
            // If guest user, then set guest user instead
            const usingRegularAccount =
              currentUser !== null && currentUser.email_address !== null;
            if (usingRegularAccount) {
              setCurrentUser(await getMe(jwt));
            } else {
              setCurrentGuestUser(await getMe(jwt));
            }
            onClose();
          } else {
            setError(result.reason);
          }
        }}
        loading={ctaLoading}
        style={{ marginTop: '1.2em', width: '100%', maxWidth: 250 }}
      >
        Save Changes
      </RoundedButton>

      <button className="changeEmailCancelButton" onClick={onClose}>
        Cancel
      </button>

      <style jsx>
        {`
          :global(.emailField) {
            border-radius: 25px !important;
            padding: 0.5em 1em;
            font: inherit;
            font-weight: 600;
            border: 2px solid lightgray;
            margin: 0.3em 0;
            width: 100%;
            max-width: 250px;
            font-size: 16px;
          }

          :global(.changeEmailCancelButton) {
            padding: 1.6em 0 0;
            background-color: transparent;
            color: inherit;
            font-weight: 700;
            font-size: 1.1em;
            text-decoration: underline;
          }

          :global(.changeEmailCancelButton:hover) {
            background-color: transparent;
          }
        `}
      </style>
    </GenericModal>
  );
});

export default ChangeEmailPopup;
