import { useRouter } from 'next/router';
import { useState } from 'react';
import { IHandleStartRegistrationProps } from 'types/ui/Registration';
import {
  identifyUser,
  trackRegistrationComplete,
} from 'utils/analytics/analytics';
import {
  getMe,
  login,
  register,
  requestPasswordResetEmail,
} from 'utils/api/auth';

export const useEmailSignIn = (
  handleSuccessfulLogin: (token: string, name: string) => Promise<void>,
  handleSuccessfulRegistration: (token: string, name: string) => Promise<void>,
  setError: (error?: string) => void
) => {
  const router = useRouter();
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [resetPasswordSuccessful, setResetPasswordSuccessful] = useState(false);

  const doEmailSignIn = async (email_address: string, password: string) => {
    setIsEmailLoading(true);
    setError(undefined);

    // setLoading(true);
    const res = await login(email_address.trim(), password);

    if (res && res.auth) {
      await handleSuccessfulLogin(res.token, res.welcomeName);
    } else {
      switch (res.reason) {
        case 'GUEST_ACCOUNT':
          localStorage.setItem('guest_email_address', res.emailAddress);
          await router.push('/register');

        case 'ACCOUNT_CLOSED':
          setError(
            'This account has been closed. If you have any questions, you can email them to: info@daymade.co.uk.'
          );
          break;

        case '2FA_REQUIRED':
          setError('This account must login using google sign in');
          break;

        case 'INVALID_CREDENTIALS':
          setError(
            'The email address and password you provided do not match our records.'
          );
          break;

        case 'FACEBOOK_LINKED_ACCOUNT':
          setError(
            'You have attempted to log into an DAYMADE account that was created using the Continue With Facebook button. Please sign in using the Continue With Facebook button.'
          );
          break;

        default:
          setError(
            'Something went wrong. Please email us if this issue persists: team@daymade.co.uk'
          );
          break;
      }
    }
  };

  const doEmailRegister = async (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string | null,
    password: string | null,
    referralId: string | null,
    agreedToMarketing: boolean,
    utmParams: any,
    facebookUserId?: string | null,
    googleUserId?: string | null,
    appleUserId?: string | null,
    overrideReferralScheme?: string | null,
    dob?: string | null,
    gender?: string | null
  ) => {
    setIsEmailLoading(true);
    setError(undefined);
    const res = await register(
      firstName,
      lastName,
      email!.trim(),
      phoneNumber,
      password,
      referralId,
      agreedToMarketing,
      utmParams,
      facebookUserId,
      googleUserId,
      appleUserId,
      overrideReferralScheme,
      dob,
      gender
    );

    if (res.successful) {
      // Send GA event.

      trackRegistrationComplete(
        email.trim(),
        res.referralId,
        firstName,
        lastName
      );

      handleSuccessfulRegistration(res.token, firstName);

      setIsEmailLoading(false);
    }else{
      setError(res.message);
    }
  };

  const onEmailResetPassword = async (email: string) => {
    setIsEmailLoading(true);
    const response = await requestPasswordResetEmail(email.toLowerCase());
    setIsEmailLoading(false);
    if (response.successful) {
      setResetPasswordSuccessful(true);
    } else if (response.code === 'GUEST_ACCOUNT') {
      localStorage.setItem('guest_email_address', response.emailAddress);
      await router.push(`/register`);
    } else {
      setError(response.reason);
    }
  };

  return {
    isEmailLoading,
    resetPasswordSuccessful,
    doEmailSignIn,
    doEmailRegister,
    onEmailResetPassword,
  };
};
