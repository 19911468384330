import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { BundleRequirementsCode } from 'utils/constants';
import { getOrderDetails } from 'api/order';
import { OrderType } from 'components/payments/PayPalAdyenCheckout';
import { costBreakdownForSubscription, fetchFreeSubscriptionWithTicket, getMostRecentSubscription } from 'utils/api/subscriptions';
import { fetchLocaliteReferralThreshold } from 'utils/api/bonus';
import { IRaffleTicketEntry, ITicketEntry } from 'types/Ticket';
import { getCookie } from 'utils/cookie';


interface DataProps {
  loading: boolean;
  orderId?: string;
  liteConfirmationPage: boolean;
  freeConfirmationPage: boolean;
  tuesdayDraw: boolean;
  fridayDraw: boolean;
  drawDates: string[]
  usedFreeSubscriptionDeal: boolean;
  entries: string[];
  promoCodeEntries: ITicketEntry[] | IRaffleTicketEntry[];
  subscriptionEnabled: boolean;
  referralThreshold: number;
  orderCostMicro: number;
  subscriptionCosts: {
    total: number;
    estimatedRenewalDate: string;
    totalWithoutDiscount: number;
    boostCost: number;
  };
  monthlyPayments: boolean;
}

export const useConfirmationData = (): DataProps => {
  const [data, setData] = useState<DataProps>({
    loading: true,
    liteConfirmationPage: false,
    freeConfirmationPage: false,
    tuesdayDraw: false,
    fridayDraw: false,
    drawDates: [],
    usedFreeSubscriptionDeal: false,
    entries: [],
    promoCodeEntries: [],
    subscriptionEnabled: false,
    referralThreshold: 0,
    orderCostMicro: 0,
    subscriptionCosts: {
      total: 0,
      estimatedRenewalDate: '',
      totalWithoutDiscount: 0,
      boostCost: 0,
    },
    monthlyPayments: false,
  });
  const router = useRouter();
  useEffect(() => {
    (async () => {
      let freeSubscriptionId =
        (router.query.freeSubscriptionId as string) || undefined;
      let orderId = (router.query.orderId as string) || undefined;

      const jwt = getCookie('jwt',null)

      if (!jwt){
        return
      }

      let entries,
        tuesdayDraw = false,
        fridayDraw = false,
        promoCodeEntries = [],
        subscriptionEnabled = false,
        orderCostMicro = 0,
        liteConfirmationPage = false,
        freeConfirmationPage = false,
        usedFreeSubscriptionDeal = false,
        monthlyPayments,
        drawDates: string[] = [];

      if (orderId) {
        const orderDetails = await getOrderDetails(
          jwt,
          orderId,
          OrderType.TICKET_ORDER
        );

        entries = orderDetails.ticket.entries.map(
          (entry: { numbers: string }) => entry.numbers
        );
        tuesdayDraw = orderDetails.drawDays.includes('TUESDAY');
        fridayDraw = orderDetails.drawDays.includes('FRIDAY');
        promoCodeEntries = orderDetails.promoCodeEntries;
        subscriptionEnabled = orderDetails.subscriptionEnabled;
        const redeemedWowcherVoucher =
          orderDetails.order.redeemedWowcherVoucher;
        orderCostMicro = parseInt(orderDetails.order.payment_cost, 10);
        liteConfirmationPage = orderDetails.ticket.ticket_type === 'LITE';
        freeConfirmationPage = false;
        usedFreeSubscriptionDeal =
          subscriptionEnabled &&
          parseInt(orderDetails.ticket.billings, 10) === 0 &&
          !redeemedWowcherVoucher;
        monthlyPayments = [
          BundleRequirementsCode.MONTHLY_LITE_SUBSCRIPTION,
          BundleRequirementsCode.MONTHLY_SUBSCRIPTION,
        ].includes(orderDetails?.bundle?.requirements_code);
        drawDates = orderDetails.drawDates
      }

      if (freeSubscriptionId) {
        const freeSubscription = await fetchFreeSubscriptionWithTicket(
          jwt,
          freeSubscriptionId
        );
        entries = freeSubscription.entries.map(
          (entry: { numbers: string }) => entry.numbers
        );
        tuesdayDraw = false;
        fridayDraw = true;
        promoCodeEntries = [];
        subscriptionEnabled = true;
        orderCostMicro = 0;
        liteConfirmationPage = false;
        usedFreeSubscriptionDeal = false;
        freeConfirmationPage = true;
      }

      const { referralThreshold } = await fetchLocaliteReferralThreshold(jwt);

      let subscriptionCosts: any = {};
      if (!freeConfirmationPage) {
        const mostRecentSubscription = (await getMostRecentSubscription(jwt))
          ?.mostRecentSubscription;
        if (mostRecentSubscription) {
          subscriptionCosts = (
            await costBreakdownForSubscription(jwt, mostRecentSubscription.id)
          )?.subscriptionCosts;
        }
      }

      setData({
        loading: false,
        orderId: orderId || freeSubscriptionId,
        liteConfirmationPage,
        freeConfirmationPage,
        tuesdayDraw,
        fridayDraw,
        drawDates,
        entries,
        usedFreeSubscriptionDeal,
        promoCodeEntries,
        subscriptionEnabled,
        referralThreshold,
        orderCostMicro,
        subscriptionCosts,
        monthlyPayments: monthlyPayments || false,
      });
    })();
  }, [router.isReady, router.query]);

  return data;
};