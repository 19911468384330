import React, { useEffect, useMemo, useState } from 'react';
import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { useDrawStore } from 'hooks/stores/useDrawStore';
import { observer } from 'mobx-react-lite';

// Users will be able to set these props in Studio.
interface DrawGlobalContextProps {
  // You might use this to override the auth URL to a test or local URL.
}

export const DrawGlobalContext = observer(
  ({ children }: React.PropsWithChildren<DrawGlobalContextProps>) => {
    const {
      upcomingDrawDate,
      usingBonusBall,
      ballRanges,
      treesPlantedCount,
      prizesWonCount,
      winnersLastWeek,
    } = useDrawStore();

    return (
      <DataProvider
        name="draw"
        data={{
          upcomingDrawDate: upcomingDrawDate?.toISOString(),
          treesPlantedCount,
          prizesWonCount,
          winnersLastWeek,
          usingBonusBall,
          ballRanges,
        }}
      >
        {children}
      </DataProvider>
    );
  }
);
